// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA3dnw4Imzn3PyalgZTX56qwb_G-zW9JFM",
  authDomain: "tobscloud-10be6.firebaseapp.com",
  projectId: "tobscloud-10be6",
  storageBucket: "tobscloud-10be6.appspot.com",
  messagingSenderId: "797861214467",
  appId: "1:797861214467:web:151d7259e7a096b77b9136",
  measurementId: "G-NVDHRZVRHP"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth();
const db = getFirestore(app);
const storage = getStorage(app);


export { auth, db, storage };
