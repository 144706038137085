import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { auth, db } from "./firebase"; // Adjust the import path accordingly
import "./Login.css"


function Login({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Authenticate user with Firebase Authentication (userdata gets saved in userCredential if signin is successful)
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      
      
      
      // Retrieve user data from Firestore
      const uid = userCredential.user.uid;
        //alert(uid);
      const docRef = doc(db, "users", uid);
        //alert(docRef);
        const docSnap = await getDoc(docRef);
        //alert(docSnap);

        if (docSnap.exists()) {
          onLogin(docSnap.data());
          //alert("Logged in successfully!");
        } else {
          // Handle the case where the user does not have an associated document in Firestore
          console.error("No document found for user:", uid);
          alert("No user data found!");
        }
      // You can also redirect the user to another page here
    } catch (error) {
      //console.error("Error signing in:", error.message);
      alert("Invalid Credentials!");
    }
  };

  return (
    <div className="login-container">
      <form onSubmit={handleLogin}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

export default Login;
