import React, { useRef, useState } from 'react';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import "./Dashboard.css";
import { db, auth, storage } from './firebase'; // adjust the import according to your firebase config file
import { Link } from 'react-router-dom';

function Dashboard({ user }) {
  const [file, setFile] = useState(null);
  const [uploadStatus, setUploadStatus] = useState('');

  const handleChange = (e) => {
    setFile(e.target.files[0]);
    setUploadStatus('');
  };

  const handleUpload = async () => {
    try {
      // Check if user is logged in
      const user = getAuth().currentUser;
      if (!user) throw new Error("User not authenticated");
console.error("1");
      
      // Check if file is selected
      if (!file) throw new Error("No file selected");
console.error("2");

      // Create a storage reference
      const storageRef = ref(getStorage(), `${user.uid}/${file.name}`);
console.error("3");

      // Upload the file
      await uploadBytesResumable(storageRef, file);
console.error("4");

      // Get the download URL
      const downloadURL = await getDownloadURL(storageRef);
console.error("5");

      // Save the reference in Firestore
      const userDocRef = doc(db, 'userProfiles', user.uid);
console.error("6");
      //await updateDoc(userDocRef, {
      //  files: arrayUnion(downloadURL)
      //});
console.error("7");

      console.log("File probably uploaded successfully!");
      setUploadStatus('probably uploaded successfully!');
      document.getElementById('upload-form').reset();
      setFile(null);
    } catch (error) {
      console.error("Error uploading file: ", error);
      setUploadStatus('Upload Failed!');
    }
  };

function checkRank(role) {
  if (!user || !user.rank) return false;

  //always return true for admin
  if (
    (typeof user.rank === 'string' && user.rank === "admin") ||
    (Array.isArray(user.rank) && user.rank.includes("admin"))
  ) return true;
  
  return (
    (typeof user.rank === 'string' && user.rank === role) ||
    (Array.isArray(user.rank) && user.rank.includes(role))
  );
}


  return (
<>
<div className="button-fixed">
    <form id="upload-form">
        <input type="file" onChange={handleChange} />
        <button type="button" onClick={handleUpload}>Upload</button>
    </form>
    <br/>
    {uploadStatus && <div>{uploadStatus}</div>}
</div>

<div className="skyBackground">
    <div className="cloud"></div>
    <div className="cloud2"></div>
    {/* Add more cloud elements if needed */}

    <div>
        <div className="welcome-text">Welcome {user.name}!</div>
        <br/>
        <div className="profile-card">
            <div className="profile-header">Your Profile</div>
            <div className="profile-info">
                <div className="info-item"><span className="label"><strong>Name:</strong></span> <span className="content">{user.name}</span></div>
                <div className="info-item"><span className="label"><strong>Info:</strong></span> <span className="content">{user.info}</span></div>
                <div className="info-item"><span className="label"><strong>Rank:</strong></span> <span className="content">{user.rank}</span></div>
            </div>
        </div>

        <br/>
        {(checkRank("babe")) && (<div className="rankbased-content">Admin or Babe Section: Access to additional resources.</div>)}
        {checkRank("admin") && (<div className="rankbased-content">Admin Section: Access to additional resources.</div>)}
        <br/>
        <div className="menu-card">
            {(checkRank("memes")) && (<Link className="" to="/memes">Go to Memes</Link>)}
            
        </div>
    </div>
</div>
    
</>
  );
}

export default Dashboard;
