import React, { useEffect } from "react";
import { getAuth } from 'firebase/auth';

async function getIPAddress(domain) {
    try {
        const response = await fetch(`https://dns.google/resolve?name=${domain}`);
        const data = await response.json();
        const ip = data.Answer[0].data;
        return ip;
    } catch (error) {
        throw new Error("Failed to resolve IP address");
    }
}

async function WebUI() {
    const user = getAuth().currentUser;
    if (!user) throw new Error("User not authenticated");

    const apiKey = user.uid;
    const hardcodedIP = "89.131.146.89"; // Replace with your hardcoded IP

    // Set a timeout to change the href after 5 seconds
    setTimeout(async () => {
        try {
            const ip = await getIPAddress("northgoat.duckdns.org");
            window.location.href = `http://${ip}:14000/?api_key=${apiKey}`;
        } catch (error) {
            console.error("Failed to resolve IP address from DuckDNS:", error.message);
        }
    }, 5000);

    // Attempt to navigate to the hardcoded IP address
    window.location.href = `http://${hardcodedIP}:14000/?api_key=${apiKey}`;
}

export default WebUI;
