import React, { useState } from "react";
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from "./Login";
import WebUI from "./WebUI";
import Dashboard from "./Dashboard";
import Memes from './Memes';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);

  const handleLogin = (userData) => {
    setIsLoggedIn(true);
    setUser(userData);
  };

  return (
    <Router>
      <Routes>
        <Route path="/login" element={isLoggedIn ? <Navigate to="/webui" /> : <Login onLogin={handleLogin} />} />
        <Route path="/webui" element={isLoggedIn ? <WebUI user={user} /> : <Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/webui" />} />
        //<Route path="/dashboard" element={isLoggedIn ? <Dashboard user={user} /> : <Navigate to="/login" />} />
        //<Route path="/memes" element={isLoggedIn ? <Memes /> : <Navigate to="/login" />} />
        //<Route path="*" element={<Navigate to="/dashboard" />} />
      </Routes>
    </Router>
  );
}

export default App;

